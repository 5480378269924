import {createStore, applyMiddleware} from 'redux'
import thunk from 'redux-thunk'
import {composeWithDevTools} from 'redux-devtools-extension'

import rootReducer from './reducers'

const initialState = { 
    norma: {listaNormaAsociada: [] },
    asociacionNorma: { listaNormaAsociadaAju: [] },
    authentication : {
        isAuthenticated: false,
        token: ''
    }
}


const middleware = [thunk]

const store = createStore(rootReducer, initialState,
    composeWithDevTools( applyMiddleware(...middleware) ))

export default store;