import React, { useState } from 'react';

import {
    BrowserRouter as Router,
    Switch,
    Route, Link
} from "react-router-dom";

import { ProtectedRoute } from './helper/protected.route';

import 'primeflex/primeflex.css';
import 'primereact/resources/themes/nova/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';

import './App.css';

import Login from './components/login/Login';
//import PrivateRoute from './components/private-route/PrivateRoute';
import DashboardContainer from './components/dashboard/DashboardContainer';
import AdminNorma from './components/admin-norma/AdminNorma';
import EditNormaComponent from './components/edit-norma/EditNormaComponent';
import AsociarNorma from './components/AsociarNorma/AsociarNorma';
import AsociarNormaDetalle from './components/AsociarNormaDetalle/AsociarNormaDetalle';

function App() {
    const initialState = {
        name: "Bienvenido",
        isUserAuthenticated: false,
        roleList: [],
        appTitle: 'Buscador de Normatividad',
        footerMsg: 'Universidad Pedagógica Nacional - Educadora de Educadores',
        adMsg: 'SGSI 2020@',
    }

    // eslint-disable-next-line no-unused-vars
    const [status, setStatus] = useState(initialState);
    
    return (
        // <main className='App'>
        <main>
            <Router>
                <hr />
                <Switch>
                    <Route path="/login"> <Login /> </Route>
                    <Route path="/dashboard"> <DashboardContainer appStatus={status} /> </Route>
                    <ProtectedRoute path="/admin-norma"> <AdminNorma appStatus={status} /> </ProtectedRoute>
                    <ProtectedRoute path="/admin-new-norma"> <EditNormaComponent /> </ProtectedRoute>
                    <ProtectedRoute path="/admin-link-norma"> <AsociarNorma /> </ProtectedRoute>
                    <ProtectedRoute path="/admin-link-detalle"> <AsociarNormaDetalle /> </ProtectedRoute>
                    <Route path="/"> <DashboardContainer appStatus={status} /> </Route>
                    <Route path="*" component={() => "404 NOT FOUND"} />
                </Switch>
                <ul style={{listStyle: "none"}}>
                    <li>
                        <Link to="/admin-norma"><i className="pi pi-ellipsis-h"></i></Link>
                    </li>
                </ul>

            </Router>
        </main>
    );
}

export default App;
