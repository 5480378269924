import { GET_NORMA_MAS_RECIENTE } from '../types'

const initialState = {
    listaNormaMasReciente: [],
    loading: true
}

// eslint-disable-next-line import/no-anonymous-default-export
export default function(state = initialState, action) {

    switch(action.type) {
        case GET_NORMA_MAS_RECIENTE:
        return {
            ...state,
            listaNormaMasReciente: action.payload.content,
            pageOptions: {
                pageable: action.payload.pageable,
                totalPages: action.payload.totalPages,
                totalElements: action.payload.totalElements,
                numberOfElements: action.payload.numberOfElements,
                size: action.payload.size,
                first: action.payload.first,
                last: action.payload.last},
            loading:false
        }
        default: return state
    }
}