import { GET_NORMA_MAS_RECIENTE, NORMA_MAS_RECIENTE_ERRORS } from '../types'
import axios from 'axios'

export const getListaNormaReciente = (page=0, size=10) => async dispatch => {
    
    try{
        const res = await axios.get(
            process.env.REACT_APP_API_URL +
                'norma?ordenar=fecha&activo=true' +
                '&page=' + page + '&size=' + size)
        dispatch( {
            type: GET_NORMA_MAS_RECIENTE,
            payload: res.data
        })
    }
    catch(e){
        dispatch( {
            type: NORMA_MAS_RECIENTE_ERRORS,
            payload: console.log(e),
        })
    }

}