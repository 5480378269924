import React from "react";
import { Route, Redirect } from "react-router-dom";
import { useSelector } from 'react-redux';

export const ProtectedRoute = ({
    children,
    ...rest
}) => {

    const { authentication } = useSelector(
        state => ({
            authentication: state.authentication
        })
    );

    const isAuthenticated = authentication.isAuthenticated; 
    return (
        <Route
            {...rest}
            render={({ location, authentication }) =>
                isAuthenticated ? (
                    children
                ) : (
                  <Redirect
                    to={{
                      pathname: "/login",
                      state: { from: location }
                    }}
                  />
                )
            }

        />
    );
};
