import { GET_TIPO_ASOCIACION_NORMA, SAVE_NORMA_ASOCIADA,
    DELETE_NORMA_ASOCIADA, SET_NORMA_ASOC_LIST_AJU,
    DELETE_NORMA_ASOCIADA_ERRORS } from '../types'

const initialState = {
    tipoAsociacionNorma: [],
    loading: true
}

// eslint-disable-next-line import/no-anonymous-default-export
export default function(state = initialState, action) {

    switch(action.type) {
        case GET_TIPO_ASOCIACION_NORMA:
            return {
                ...state,
                tipoAsociacionNorma: action.payload,
                loading:false
            }
        case SAVE_NORMA_ASOCIADA:
            return {
                ...state,
                normaAsociada: action.payload,
                loading:false
            }
        case DELETE_NORMA_ASOCIADA:
            return {
                ...state,
                borrarNormaAsocMsg: action.payload.data,
                borrarNormaAsocStatus: action.payload.status,
                loading:false
            }    
        case DELETE_NORMA_ASOCIADA_ERRORS:
            return {
                ...state,
                borrarNormaAsocMsg: action.payload.data,
                borrarNormaAsocStatus: action.payload.status,
                loading:false
            }              
        case SET_NORMA_ASOC_LIST_AJU:
            return {
                ...state,
                listaNormaAsociadaAju: action.payload,
                loading:false
            }    
        default: return state
    }
}