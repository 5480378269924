import { NORMA_ASOCIADA_ERRORS, SAVE_NORMA, SET_SELECTED_NORMA } from '../types'
import { SAVE_NORMA_FILE, NORMA_FILE_ERRORS, NORMA_ERRORS, GET_NORMA_FILE } from '../types'
import { NORMA_EXCEL_ERRORS, GET_NORMA_EXCEL } from '../types'
import { GET_NORMA_ASOCIADA_LIST, SAVE_NORMA_ASOCIADA } from '../types'
import { DELETE_NORMA_ASOCIADA, DELETE_NORMA } from '../types'

const initialState = {
    loading: true
}

const normaReducer = (state = initialState, action) => {
    switch(action.type) {
        case SAVE_NORMA:
            return {
                ...state,
                normaAction: action.payload.data,
                normaStatus: action.payload.status,
                loading:false
            }
        case SAVE_NORMA_FILE:
            return {
                ...state,
                fileMessage: action.payload.data.responseMessage,
                fileStatus: action.payload.status,
                loading:false
            }            
        case GET_NORMA_FILE:
            return {
                ...state,
                fileMessage: "", //action.payload.statusText,
                fileStatus: action.payload.status,
                fileContent: action.payload.data,
                loading:false
            }            
        case NORMA_ERRORS:
            return {
                ...state,
                normaAction: action.payload.response.data.message,
                normaStatus: action.payload.response.status,
                loading:false
            }            
        case NORMA_FILE_ERRORS:
            return {
                ...state,
                fileMessage: action.payload.message,
                fileStatus: action.payload.name,
                fileContent: '',
                loading:false
            }
        case SET_SELECTED_NORMA:
            return {
                ...state,
                selectedNorma: action.payload
            }
        case GET_NORMA_ASOCIADA_LIST:
            return {
                ...state,
                listaNormaAsociada: action.payload
            }
        case SAVE_NORMA_ASOCIADA:
            return {
                ...state,
                normaAsocAction: action.payload.data,
                normaAsocStatus: action.payload.status,
                loading:false
            }            
        case NORMA_ASOCIADA_ERRORS:
            return {
                ...state,
                normaAsocAction: action.payload.data.message,
                normaAsocStatus: action.payload.status,
                loading:false
            }
        case DELETE_NORMA_ASOCIADA:
            return {
                ...state,
                normaAsocAction: action.payload.data,
                normaAsocStatus: action.payload.status,
                loading:false
            }
        case DELETE_NORMA:
                return {
                    ...state,
                    normaAction: action.payload.data,
                    normaStatus: action.payload.status,
                    loading:false
                }            
        case GET_NORMA_EXCEL:
            return {
                ...state,
                fileContent: action.payload.data,
                loading:false
            } 
        case NORMA_EXCEL_ERRORS:
                return {
                    ...state,
                    fileContent: '',
                    loading:false
                }
        default: return state
    }
}

export default normaReducer;