import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from "react-router-dom";

import './AsociarNorma.css';

import { Button } from 'primereact/button';
import { confirmDialog } from 'primereact/confirmdialog';
import { DataView } from 'primereact/dataview';
import { Toast } from 'primereact/toast';
import { Toolbar } from 'primereact/toolbar';

import { getNormaAsociada, deleteNormaAsociada } from '../../store/actions/normaActions';
import { getNormaFile } from '../../store/actions/normaActions';
import { showFileLink } from '../../helper/Util';

import NormaCard from '../norma-card/NormaCard'; 

const AsociarNorma = (props) => {
    const dispatch = useDispatch();
    const toast = useRef(null);
    let asociacionABorrar = null;

    // eslint-disable-next-line no-unused-vars
    const [layout, setLayout] = useState('list');

    const [ updateState] = React.useState();
    // eslint-disable-next-line no-unused-vars, react-hooks/exhaustive-deps
    const forceUpdate = React.useCallback(() => updateState({}), []);

    const history = useHistory();

    // eslint-disable-next-line no-unused-vars
    const { selectedNorma, listaNormaAsociada, normaAsocState, listaNormaAsocAju } = useSelector(
        state => ({
            selectedNorma: state.norma.selectedNorma,
            listaNormaAsociada: state.norma.listaNormaAsociada,
            listaNormaAsocAju: state.asociacionNorma.listaNormaAsocAju,
            normaAsocState: state.norma.normaAsocStatus
        })
    )

    // eslint-disable-next-line no-unused-vars
    const [panelCollapsed, setPanelState] = useState(true);

    const [listaNormasAsociadas, setListaNormasAsociadas] = useState([]);

    useEffect( () => {
        dispatch(getNormaAsociada(selectedNorma))
        .then( (res) => {
            setListaNormasAsociadas(res);
        });
    }, [dispatch, panelCollapsed, selectedNorma])
    
    const showToast = (severityValue, summaryValue, detailValue) => {   
        toast.current.show({severity: severityValue, summary: summaryValue, detail: detailValue});   
    }

    const getFile = (normaFile) => {
        dispatch(getNormaFile(normaFile))
        .then( (res) => {
            if ( res.fileMessage !== "") {
                toast.current.show({ severity: 'error', summary: 'Atención', 
                        detail:  "El archivo no está disponible para su revisión en estos momentos." });
            } else {
                showFileLink(res.fileContent);
            }
        })
        .catch( (e) => {
            console.log("*** e*", e)
        });
    }  

    const borraNormaAsociada = () => {
        dispatch(deleteNormaAsociada(asociacionABorrar))
        .then((res) => {
            if (res.borrarNormaAsocStatus === 200) {
                dispatch(getNormaAsociada(selectedNorma))
                .then( (resp) => {
                    setListaNormasAsociadas(resp);
                });;
                showToast('info', 'Atención', 'Se eliminó la asociación correctamente');
            } else {
                showToast('error', 'Atención', 'Error eliminando la asociación.');
            }
        });
    }

    const reject = () => {

    }

    const confirmDeleteAsoc = (entidad) => {
        asociacionABorrar = entidad;
        let mensaje  =   '¿Desea borrar esta norma asociada? ';
        confirmDialog({
            message: mensaje,
            header: 'Confirmación de borrado',
            icon: 'pi pi-info-circle',
            acceptClassName: 'p-button-danger',
            acceptLabel: 'Borrar',
            rejectLabel: 'Cancelar',
            accept: borraNormaAsociada,
            reject: reject
        });
    };
    
    const itemTemplate = (product, layout) => {
        if (!product) {
            return;
        }

        return renderListItem(product);
    }

    const renderListItem = (data) => {
        return (
            <div className="p-col-12">
                <div className="product-item">
                    <div className="product-detail">
                        <div className="product-name">{data.normaAsociadaId.nombre}</div>
                        <div className="product-description">Entidad Emisora:&nbsp;
                            {data.normaAsociadaId.entidadEmisora.descripcion}</div>
                        <div className="product-description">Tipo de Norma:&nbsp;  
                            {data.normaAsociadaId.tipoNorma.descripcion}</div>
                        <i className="pi pi-calendar product-category-icon"></i>
                        <span className="product-category">{data.normaAsociadaId.fechaElaboracion}</span>
                    </div>
                    <div className="product-action">
                        <Button label="Ver archivo" icon="pi pi-download" iconPos="right" 
                                onClick={() => getFile(data.normaAsociadaId.rutaArchivo)}/>
                        <Button label="Desasociar" icon="pi pi-ban" iconPos="right" 
                                className="p-button-warning"
                                onClick={() => confirmDeleteAsoc(data.id)}/>
                    </div>
                </div>
            </div>
        );
    }

    const newNormaOnClick = () => {
        setListaNormasAsociadas([]);
        history.push('/admin-link-detalle');
    }

    const volver = () => {
        setListaNormasAsociadas(null);
        history.goBack();
    };

    const rightContents = (
        <React.Fragment>
            <Button label="Nueva asociación" icon="pi pi-plus" className="p-button-primary p-mr-2" 
                onClick={newNormaOnClick} />
            <Button label="Volver" icon="pi pi-chevron-left" className="p-button-secondary" 
                onClick={volver} />
        </React.Fragment>
    )

    return (
        <div>
            <Toast ref={toast} />
            <h3 className="p-sm-12 p-col-12"  style={{color: "black"}}>Asociar Norma</h3>
            <Toolbar right={rightContents} />
            <div className="p-grid">
                <div className="p-sm-4 p-col-12 p-mt-2" >
                    <NormaCard  norma={selectedNorma} masConsultadas={false}></NormaCard> 
                </div>

                <div className="p-sm-8 p-col-12">
                    <div className="datascroller-demo p-mt-2">
                        <div className="card">
                            <DataView value={listaNormasAsociadas} layout={layout} header="Normas Asociadas"
                                    itemTemplate={itemTemplate} paginator rows={5} />
                        </div>

                    </div>
                    
                </div>
            </div>
        </div>
    );
}

AsociarNorma.propTypes = {};

AsociarNorma.defaultProps = {};

export default AsociarNorma;
