import React, {useRef}  from 'react';
import { useDispatch } from 'react-redux';

import { getNormaFile } from '../../store/actions/normaActions';
import { showFileLink } from '../../helper/Util';

import { Button } from 'primereact/button';
import { Card } from 'primereact/card';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import { Toast } from 'primereact/toast';

const NormaCard = (props) => {
    const toast = useRef(null);
    const dispatch = useDispatch();
    const norma = props.norma;
    const masConsultadas = props?.masConsultadas;

    const getFile = (normaFile) => {
        dispatch(getNormaFile(normaFile))
        .then( (res) => {
            if ( res.fileMessage !== "") {
                toast.current.show({ severity: 'error', summary: 'Atención', 
                        detail: "No se pudo leer el archivo asociado." });
            } else {
                showFileLink(res.fileContent);
            }
        })
        .catch( (e) => {
            console.log("*** e*", e)
        });
    }  

    var numStar = 0;
    var starIcon = [];
    if (masConsultadas === true) {
        numStar = (Math.min(5, Math.floor(norma.numConsultas/5))); 
        for (var k=1; k <= numStar; k++) {
            starIcon.push(<i className="pi pi-star" key={k} 
                style={{'fontSize': '0.75em', 'color': 'lightskyblue' }}></i>);
        }
    }

    let textoInactivo = "";
    if (norma.esActivo === false) {
        textoInactivo =
            <p>
                <small style={ {"color" : "red" } }> INACTIVO</small>
            </p>
    }

    return (
        <Card subTitle={norma.nombre} title={starIcon} >
            <Toast ref={toast} />
            <div className="p-fluid">
                <div className="p-field p-col-12 p-md-12">
                    <span className="p-float-label">
                        <InputText id="fechaElaboracion" 
                            value={norma.fechaElaboracion} readOnly/>
                        <label htmlFor="fechaElaboracion">Fecha de Expedición</label>
                    </span>
                </div>

                <div className="p-field p-col-12 p-md-12">
                    <span className="p-float-label">
                        <InputText id="tipoNorma" 
                            value={norma.tipoNorma.descripcion} readOnly/>
                        <label htmlFor="tipoNorma">Tipo de Norma</label>
                    </span>
                </div>

                <div className="p-field p-col-12 p-md-12">
                    <span className="p-float-label">
                        <InputText id="entidadEmisora" 
                            value={norma.entidadEmisora.descripcion} readOnly/>
                        <label htmlFor="entidadEmisora">Entidad Emisora</label>
                    </span>
                </div>

                <div className="p-field p-col-12 p-md-12">
                    <span className="p-float-label">
                        <InputTextarea id="textarea" value={norma.descripcion} 
                            rows={3} readOnly/>
                        <label htmlFor="textarea">Descripción</label>
                    </span>
                </div>
            </div>
            {textoInactivo}
            <Button label="Ver archivo" icon="pi pi-download" iconPos="right" 
                onClick={() => getFile(norma.rutaArchivo)}/>
        </Card>
    )
}

export default NormaCard;