import { GET_ENTIDAD_EMISORA, ENTIDAD_EMISORA_ERRORS } from '../types'
import axios from 'axios'

export const getEntidadEmisora = () => async dispatch => {
    
    try{
        const res = await axios.get(
            process.env.REACT_APP_API_URL + 'tipo-lista/detalle-agrupado?texto=ENTIDAD_EMITE')
        dispatch( {
            type: GET_ENTIDAD_EMISORA,
            payload: res.data
        })
    }
    catch(e){
        dispatch( {
            type: ENTIDAD_EMISORA_ERRORS,
            payload: e
        })
    }

}