import { GET_TIPOS_NORMA } from '../types'

const initialState = {
    loading: true
}

const tiposNormaReducer = (state = initialState, action) => {

    switch(action.type) {
        case GET_TIPOS_NORMA:
        return {
            ...state,
            tiposNorma: action.payload,
            loading:false
        }
        default: return state
    }
}

export default tiposNormaReducer;