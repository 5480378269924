import { GET_LISTA_NORMA, SAVE_LISTA_NORMA_PARAMS } from '../types'

const initialState = {
    listaNorma: [],
    loading: true
}

// eslint-disable-next-line import/no-anonymous-default-export
//export default function(state = initialState, action) {
const listaNormaReducer = (state = initialState, action) => {

    switch(action.type) {
        case GET_LISTA_NORMA:
            return {
                ...state,
                listaNorma: action.payload.content,
                pageOptions: {
                    pageable: action.payload.pageable,
                    totalPages: action.payload.totalPages,
                    totalElements: action.payload.totalElements,
                    numberOfElements: action.payload.numberOfElements,
                    size: action.payload.size,
                    first: action.payload.first,
                    last: action.payload.last},
                loading:false
            }
        case SAVE_LISTA_NORMA_PARAMS:
            return {
                ...state,
                parametros: action.payload
            } 
        default: return state
    }
}

export default listaNormaReducer;