import { GET_LISTA_NORMA, LISTA_NORMA_ERRORS, SAVE_LISTA_NORMA_PARAMS } from '../types'
import axios from 'axios'

export const getListaNorma = (parametros) => {
    return async (dispatch, getState) => {
        const params = { 
            contenido: parametros?.buscarContenido,
            entidad: parametros?.entidad !== "" ? parametros.entidad.id : '',
            desde: parametros?.fechaElaboracionDesde !== "" ? parametros.fechaElaboracionDesde : '',
            hasta: parametros?.fechaElaboracionHasta !== "" ? parametros.fechaElaboracionHasta : '',
            numero: parametros?.numeroNorma !== "" ? parametros.numeroNorma : '',
            texto: parametros?.textoBuscar !== "" ? parametros.textoBuscar : '',
            tiponorma: parametros?.tipoNorma !== "" ? parametros.tipoNorma.id : '',
            activo: parametros?.activo !== "" ? parametros.activo : true,
            page: parametros?.page !== "" ? parametros.page : 0,
            size: parametros?.size !== "" ? parametros.size : 10
        };

        try {
            const res = await axios.get( 
                process.env.REACT_APP_API_URL + 'norma/search', 
                    { params: params })

            dispatch({type: GET_LISTA_NORMA, payload: res.data});
            dispatch({type: SAVE_LISTA_NORMA_PARAMS, payload: parametros});
        }
        catch (e) {
            dispatch({type: LISTA_NORMA_ERRORS, payload: e});
        }
        return getState().listaNorma.pageOptions;
    }
};

/*
export const saveListaNormaParams = (parametros) => {
    return (dispatch, getState) => {
        dispatch({type: SAVE_LISTA_NORMA_PARAMS, payload: parametros});
        return getState().listaNorma.parametros;
    }
};
*/