import { GET_NORMA_MAS_CONSULTADA, NORMA_MAS_CONSULTADA_ERRORS } from '../types'
import axios from 'axios'

export const getListaNormaConsultada = (page=0, size=10) =>  {
    return async (dispatch, getState) => {
        try{
            const res = await axios.get(
                process.env.REACT_APP_API_URL +
                    'norma?ordenar=numConsultas&activo=true' +
                        '&page=' + page + '&size=' + size)
            dispatch( {
                type: GET_NORMA_MAS_CONSULTADA,
                payload: res.data
            })
        }
        catch(e){
            dispatch( {
                type: NORMA_MAS_CONSULTADA_ERRORS,
                payload: console.log(e),
            })
        }
        return getState().listaNormaMasConsultada.pageOptions;
    }
}