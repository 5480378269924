import { combineReducers } from 'redux';
import entidadEmisoraReducer from './entidadEmisoraReducer';
import tiposNormaReducer from './tiposNormaReducer';
import listaNormaReducer from './listaNormaReducer';
import asociacionNormaReducer from './asociacionNormaReducer';
import listaNormaMasConsultadaReducer from './listaNormaMasConsultadaReducer';
import listaNormaMasRecienteReducer from './listaNormaMasRecienteReducer';
import normaReducer from './normaReducer';
import authenticationReducer from './authenticationReducer';

export default combineReducers({
    entidadEmisora: entidadEmisoraReducer,
    tiposNorma: tiposNormaReducer,
    listaNorma: listaNormaReducer,
    asociacionNorma: asociacionNormaReducer, 
    listaNormaMasConsultada: listaNormaMasConsultadaReducer,
    listaNormaMasReciente: listaNormaMasRecienteReducer,
    norma: normaReducer,
    authentication: authenticationReducer
})