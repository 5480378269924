import React from 'react';
import './header.css'
import manual from '../../../assets/manual_buscador_normativo.pdf';

function Header(props) {
    return (
        <header className="main-head">
            <div >
                <h2 style={{ marginBottom: '0', align: 'center' }}>{props.appTitle}</h2>
                <small><i>Los datos aquí publicados son de caracter informativo.</i></small>
            </div>
            <a href={manual} rel='noreferrer' target='_blank' className="manual-usuario">
                <i className="pi pi-file-pdf"> Manual del Usuario</i>
            </a>
        </header>
    );
}

export default Header;