import React, { useRef, useState, useEffect, useContext } from 'react';
import { Redirect, useLocation } from "react-router-dom";
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import AuthContext from "../../context/AuthProvider";

import { getToken } from '../../store/actions/userActions';
import { Button } from 'primereact/button';
import { Divider } from 'primereact/divider';
import { Panel } from 'primereact/panel';

import { logout } from '../../store/actions/userActions';

import './Login.css'

export const Login = () => {
    const { setAuth } = useContext(AuthContext);
    const dispatch = useDispatch();
    const userRef = useRef();
    const errRef = useRef();
    const history = useHistory();

    const [user, setUser] = useState('');
    const [pwd, setPwd] = useState('');
    const [errMsg, setErrMsg] = useState('');
    const [success, setSuccess] = useState(false);

    useEffect(() => {
        userRef.current.focus();
    }, [])

    useEffect(() => {
        setErrMsg('');
    }, [user, pwd])

    const { state } = useLocation();

    const { from } = state || { from: { pathname: "/" } };
    // eslint-disable-next-line no-unused-vars
    const [redirectToReferrer, setRedirectToReferrer] = useState(false);

    if (redirectToReferrer) {
        return <Redirect to={from} />;
    }

    const handleSubmit = async (e) => {
        e.preventDefault();

        dispatch(getToken(user, pwd))
        .then((res) => {
            if (res === '') {
                setUser('');
                setPwd('');
                setSuccess(true);
                setAuth({ user, pwd });
                history.push(from.pathname);
            } else {
                setErrMsg(res);
                setSuccess(false);
            }
        });
    }

    const logoutOnClick = () => {
        dispatch(logout()).then(() => {
            history.push('/');
        });
    };

    return (
        <Panel header="Advertencia">
            <p>
                POR FAVOR LEA ESTE AVISO ANTES DE CONTINUAR
            </p>

            <br></br>
            <p className='advertencia'>
                Este es un sitio web protegido de la Universidad Pedagógica Nacional. Causar daño intencionalmente a este sitio web, o a cualquier instalación electrónica o datos de la Universidad a través de la transmisión consciente de cualquier programa, información, código o comando es ilegal.
            </p>
            <br></br>
            <p className='advertencia'>
                Este sistema y el equipo relacionado están sujetos a monitoreo. La información sobre los usuarios puede obtenerse y divulgarse al personal autorizado, incluidas las autoridades encargadas de hacer cumplir la ley, para fines oficiales. El acceso o uso de este sitio web constituye el consentimiento a estos términos.
            </p>
            <p>
                <br></br>
                <Button label="Salir" icon="pi pi-sign-out" className="p-button-primary" 
                onClick={logoutOnClick}/>
            </p>
            <Divider align="left">
                <div className="inline-flex align-items-center">
                    <i className="pi pi-user mr-2"></i>
                </div>
            </Divider>

            <div className='login'>
                <>
                    {success ? (
                        <section>
                            <h1>You are logged in!</h1>
                            <br />
                        </section>
                    ) : (
                        <section>
                            <h2 className='titulo'>Ingresar</h2>
                            <p ref={errRef} className={errMsg ? "errmsg" : "offscreen"} aria-live="assertive">{errMsg}</p>
                            <form onSubmit={handleSubmit}>
                                <label htmlFor="username">Usuario:</label>
                                <input
                                    type="text"
                                    id="username"
                                    ref={userRef}
                                    autoComplete="off"
                                    onChange={(e) => setUser(e.target.value)}
                                    value={user}
                                    required
                                />

                                <label htmlFor="password">Contraseña:</label>
                                <input
                                    type="password"
                                    id="password"
                                    onChange={(e) => setPwd(e.target.value)}
                                    value={pwd}
                                    required
                                />
                                <button>Ingresar</button>
                            </form>
                        </section>
                    )}
                </>
            </div>
        </Panel>
    );
}

export default Login;