import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom'

// eslint-disable-next-line no-unused-vars
import { Badge } from 'primereact/badge';
import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Dialog } from 'primereact/dialog';
import { Dropdown } from 'primereact/dropdown';
import { InputTextarea } from 'primereact/inputtextarea';
// import { InputText } from 'primereact/inputtext';
import { ListBox } from 'primereact/listbox';
import { Ripple } from 'primereact/ripple';
import { Toast } from 'primereact/toast';
import { classNames } from 'primereact/utils';

import './TablaResultados.css';

import NormaCard from '../norma-card/NormaCard';
import EditNormaComponent from '../edit-norma/EditNormaComponent';
import { setSelectedNorma, saveNormaAsociada } from '../../store/actions/normaActions';
import { getListaNorma } from '../../store/actions/listaNormaActions';
import { getListaNormaConsultada } from '../../store/actions/listaNormaConsultadaActions';
import { getListaNormaReciente } from '../../store/actions/listaNormaRecienteActions';
import { getNormaAsociada } from '../../store/actions/normaActions';
import { saveNorma, deleteNorma } from '../../store/actions/normaActions';
import { getNormaFile } from '../../store/actions/normaActions';
import { showFileLink } from '../../helper/Util';

class TablaResultados extends Component {

    constructor(props) {
        super(props);
        this.state = { 
            admin: this.props.admin,
            asociar: this.props.asociar,
            asociarDialog: false,
            borrarNormaTexto: "",
            currentPage: 0,
            entity: this.props.norma,
            entityDialog: false,
            first1: 0,
            loading: false,
            numRegistros: parseInt(this.props.numRegistros), //dataList: this.props.listaNormas.content,
            pageInputTooltip: 'Presione la tecla \'Enter\' para ir a esta página.',
            selectedTipoAsociacion: {},
            submitted: false,
            totalRecords: 0,
            verAsociadaDialog: false,
            verBorraNormaDialog: false,
        }

        // this.dataList = this.props.listaNormas;

        this.actionBodyTemplate = this.actionBodyTemplate.bind(this);
        this.borrarNorma = this.borrarNorma.bind(this);
        this.descripcionBodyTemplate = this.descripcionBodyTemplate.bind(this);
        this.editEntity = this.editEntity.bind(this);
        this.entidadBodyTemplate = this.entidadBodyTemplate.bind(this);
        this.getArchivosNorma = this.getArchivosNorma.bind(this);
        this.getAsociadas = this.getAsociadas.bind(this);
        this.guardaAsociacion = this.guardaAsociacion.bind(this);
        this.hideDialog = this.hideDialog.bind(this);
        this.linkEntity = this.linkEntity.bind(this);
        this.nombreBodyTemplate = this.nombreBodyTemplate.bind(this);
        this.onCustomPage1 = this.onCustomPage1.bind(this);
        this.onPageInputChange = this.onPageInputChange.bind(this);
        this.onPageInputKeyDown = this.onPageInputKeyDown.bind(this);
        this.reject = this.reject.bind(this);
        this.tipoBodyTemplate = this.tipoBodyTemplate.bind(this);
    }

    componentDidMount() { 
        if (this.props.paginador && this.props.paginador.totalElements) {
            this.setState({
                totalRecords: this.props.paginador.totalElements
            })
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.paginador !== this.props.paginador) {
            this.setState({
                totalRecords: this.props.paginador.totalElements
            })
        }
    }

    onCustomPage1(event) {
        this.setState({
            first1: event.first,
            numRegistros: event.rows,
            currentPage: event.page,
            loading: true
        });

        if (this.props.tabla === "ConsultaUsuario") {
            let params = {
                ...this.props.parametrosBusqueda,
                size: event.rows,
                page: event.page}
            this.props.getListaNorma(params).then ((res) => {
                this.setState({ loading: false });
            });
        } /*else if (this.props.tabla === "MasConsultadas") {
            this.props.getListaNormaConsultada(event.page, event.rows);
        } else if (this.props.tabla === "Recientes") {
            this.props.getListaNormaReciente(event.page, event.rows);
        }*/
    }

    onPageInputKeyDown(event, options) {
        if (event.key === 'Enter') {
            const page = parseInt(this.state.currentPage);
            if (page < 1 || page > options.totalPages) {
                this.setState({ pageInputTooltip: `El valor debe estar entre 1 y ${options.totalPages}.`})
            }
            else {
                const first = this.state.currentPage ? options.rows * (page - 1) : 0;

                this.setState({ first1: first, pageInputTooltip: 'Presione la tecla \'Enter\' para ir a esta página.' });
            }
        }
    }

    onPageInputChange(event) {
        this.setState({ currentPage: event.target.value });
    }

    editEntity(entity) {
        this.setState({
            entity: { ...entity },
            entityDialog: true
        });
        // TODO: Invocar consulta para refrescar tabla resultados una vez editado o creado
    };

    getAsociadas(entity) {
        this.props.getNormaAsociada(entity);
        this.setState({verAsociadaDialog: true});
    }

    guardaAsociacion() {
        if (!this.state?.selectedTipoAsociacion?.descripcion) {
            this.toast.show({ severity: 'error', summary: 'Atención', 
                detail: 'Seleccione el tipo de asociación', life: 3000 });
        } else if (this.props.selectedNorma.id === this.state.entity.id) {  
            this.toast.show({ severity: 'error', summary: 'Atención', 
                detail: 'No se puede asociar una norma a sí misma.', life: 3000 });
        } else {
            const normaAsociacion = {
                version: 0,
                normaId: this.props.selectedNorma,
                normaAsociadaId: this.state.entity,
                tipoAsociacionId: this.state.selectedTipoAsociacion
            }

            if (normaAsociacion.normaId.id !== normaAsociacion.normaAsociadaId.id) {
                // eslint-disable-next-line no-unused-vars
                let respuesta = this.props.saveNormaAsociada(normaAsociacion)
                .then( (res) => {
                    if (res.normaAsocStatus === 201) {
                    this.toast.show({ severity: 'success', summary: 'Atención', 
                        detail: 'Se creó la asociación correctamente.', life: 3000 });
                    this.hideDialog('asociarDialog');
                } else {
                    this.toast.show({ severity: 'error', summary: 'Atención', 
                        detail: 'Error creando la asociación: ' +
                        this.props.normaAsocAction, life: 3000 });
                }
                });
            } else {
                this.toast.show({ severity: 'warning', summary: 'Atención', 
                    detail: 'No se puede asociar una norma a sí misma.', life: 3000 });
            }
        }
    }

    getArchivosNorma(normaEntity) {
        // eslint-disable-next-line no-unused-vars
        let mensaje = this.props.getNormaFile(normaEntity.rutaArchivo)
            .then( (res) => {
                if ( res.fileMessage !== "") {
                    this.toast.show({ severity: 'error', summary: 'Atención', 
                            detail: "El archivo no está disponible para su revisión en estos momentos." });
                } else {
                    showFileLink(res.fileContent);

                    normaEntity.numConsultas = normaEntity.numConsultas + 1;
                    this.props.saveNorma(normaEntity);
                }
            })
            .catch( (e) => {
                console.log("*** e*", e)
            });
    }    

    getCopiarLink(normaEntity) {
        // navigator.clipboard.writeText(
        //     encodeURI(process.env.REACT_APP_API_URL + 'files/' + normaEntity.rutaArchivo));
        // this.toast.show({ severity: 'info', summary: 'Atención', 
        //     detail: 'La Url del archivo ha sido copiada al clipboard', life: 3000 });
        let linkToGo = encodeURI(process.env.REACT_APP_API_URL + 'files/' + normaEntity.rutaArchivo);
        if (typeof (navigator.clipboard) == 'undefined') {
            console.log('navigator.clipboard');
            var textArea = document.createElement("textarea");
            textArea.value = linkToGo;
            textArea.style.position = "fixed";  //avoid scrolling to bottom
            document.body.appendChild(textArea);
            textArea.focus();
            textArea.select();
        
            try {
                var successful = document.execCommand('copy');
                var msg = successful ? 'La Url del archivo ha sido copiada exitósamente' :
                    'Se presentó un error copiando la Url del archivo';
                this.toast.show({ severity: 'info', summary: 'Atención', 
                    detail: msg, life: 3000 });;
            } catch (err) {
                this.toast.show({ severity: 'error', summary: 'Atención', 
                    detail: 'Se presentó un error copiando la Url del archivo', life: 3000 });;
            }
        
            document.body.removeChild(textArea)
            return;
        }
        navigator.clipboard.writeText(linkToGo).then(function () {
            this.toast.show({ severity: 'info', summary: 'Atención', 
                    detail: msg, life: 3000 });;
        }, function (err) {
            this.toast.show({ severity: 'error', summary: 'Atención', 
                    detail: 'Se presentó un error copiando la Url del archivo', life: 3000 });;
        });
    }
   
    linkEntity(entity) {
        this.props.setSelectedNorma(entity);
    }

    actionBodyTemplate(rowData) {
        let botonAsociar = '';
        let botonEditar = '';
        let botonBorrar = '';
        if (this.state.admin) {
            if (!this.state.asociar) {
                botonAsociar = <Link to="/admin-link-norma">
                    <Button icon="pi pi-sitemap"
                    className="p-button-rounded p-button-success p-mr-2" 
                    onClick={() => this.linkEntity(rowData.norma)}
                    tooltip="Asociar Normas" />
                    </Link>
            } else {
                botonAsociar = 
                    <Button icon="pi pi-share-alt"
                        className="p-button-rounded p-button-success p-mr-2" 
                        onClick={() => this.setState({entity: rowData.norma, asociarDialog: true})}
                        tooltip="Asociar esta Norma" />
            }

            botonEditar = 
                <Button icon="pi pi-pencil"
                    className="p-button-rounded p-button-primary p-mr-2" 
                    onClick={() => this.editEntity(rowData.norma)} tooltip="Editar" />

            botonBorrar = 
                <Button icon="pi pi-trash"
                    className="p-button-rounded p-button-warning p-mr-2" 
                    onClick={() => 
                        this.confirmDeleteNorma(rowData.norma)}
                    tooltip="Borrar" />
        }

        let botonAsociadas = '';

        if ( rowData.numNormasAsoc> 0 ) {
            botonAsociadas = 
                <span>
                    <Button type="button"
                        className="p-button-rounded p-button-text"
                        onClick={() => this.getAsociadas(rowData.norma)} tooltip="Normas Asociadas">
                        <i className="pi pi-sitemap p-mr-4 p-text-secondary p-overlay-badge"
                            style={{ fontSize: '2rem' }} >
                            <Badge value={rowData.numNormasAsoc} severity="warning" ></Badge></i>
                    </Button>
                </span>
        }

        let botonCopiarLink = '';

        botonCopiarLink = 
            <span>
                <Button type="button"
                    className="p-button-rounded p-button-text"
                    onClick={() => this.getCopiarLink(rowData.norma)} tooltip="Copiar Link">
                    <i className="pi pi-share-alt p-mr-4 p-text-secondary"
                        style={{ fontSize: '2rem' }} >
                    </i>
                </Button>
            </span>

        return (
            <React.Fragment>
                <Button icon="pi pi-file-pdf" className="p-button-rounded p-button-primary p-mr-2" 
                    onClick={() => this.getArchivosNorma(rowData.norma)} tooltip="Ver archivo" />
                {botonEditar}
                {botonCopiarLink}
                {botonAsociar}
                {botonAsociadas}
                {botonBorrar}
            </React.Fragment>
        );
    }

    tipoBodyTemplate(rowData) {
        let textoInactivo = "";
        if (rowData.norma.esActivo === false) {
            textoInactivo =
            <small style={ {"color" : "red" } }> INACTIVO</small>
        }

        return (
            <React.Fragment>
                <span className="p-column-title">Tipo</span>
                {rowData.norma.tipoNorma.descripcion} - {rowData.norma.nombre} - &nbsp;
                {rowData.norma.entidadEmisora.descripcion} &nbsp;
                <br></br> <small>Expedición: {rowData.norma.fechaElaboracion}</small>
                {textoInactivo}
            </React.Fragment>
        )
    }

    entidadBodyTemplate(rowData) {
        return (
            <React.Fragment>
                <span className="p-column-title">Entidad</span>
                {rowData.norma.entidadEmisora.descripcion}
            </React.Fragment>
        )
    }

    nombreBodyTemplate(rowData) {
        return (
            <React.Fragment>
                <span className="p-column-title">Nombre</span>
                {rowData.norma.nombre}
            </React.Fragment>
        )
    }

    descripcionBodyTemplate(rowData) {
        return (
            <React.Fragment>
                <span className="p-column-title">Descripción</span>
                {rowData.norma.descripcion}
            </React.Fragment>
        )
    }

    hideDialog(name) {
        this.setState({
            [`${name}`]: false
        });
    }

    renderFooter(name) {
        return (
            <div>
                <Button label="Cancelar" icon="pi pi-times" onClick={() => this.hideDialog(name)} 
                    className="p-button-secondary" />
                <Button label="Asociar" icon="pi pi-check" onClick={() => this.guardaAsociacion()} 
                    autoFocus />
            </div>
        );
    }

    reject () {}

    borrarNorma() {
        if (this.state.borrarNormaTexto !== "" ) {
            this.props.deleteNorma(this.state.entity, this.state.borrarNormaTexto)
            .then( (res) => {
                if (res === 200) {
                    this.toast.show({ severity: 'success', summary: 'Atención', 
                        detail: 'La norma ha sido borrada.', life: 3000 });
                    this.hideDialog('verBorraNormaDialog');

                    let params = {
                        ...this.props.parametrosBusqueda,
                        size: 5,
                        page: 0}
                    this.props.getListaNorma(params).then ((res) => {
                        this.setState({ loading: false });
                    });
                } else {
                    this.toast.show({ severity: 'error', summary: 'Atención', 
                        detail: 'Error eliminando la norma: ' +
                        this.props.normaAction, life: 3000 });
                }
            });
        } else {
            this.toast.show({ severity: 'warn', summary: 'Atención', 
            detail: 'Es obligatorio ingresar el motivo de borrado.', life: 3000 }); 
        }
    }

    confirmDeleteNorma (entidad) {
        this.setState({
            entity: { ...entidad },
            verBorraNormaDialog: true
        });
    };

    render() { 
        let componenteEditar;
        if (this.state.admin) {
            componenteEditar = <EditNormaComponent selectedRow={this.state.entity} 
            onHideFunction={() => this.hideDialog('entityDialog')} tablaResultados={true}/>
        } else {
            componenteEditar = <NormaCard norma={this.state.entity} masConsultadas={false} />
        }

        const template1 = {
            layout: 'PrevPageLink PageLinks NextPageLink RowsPerPageDropdown CurrentPageReport',
            'PrevPageLink': (options) => {
                return (
                    <button type="button" className={options.className} onClick={options.onClick} 
                            disabled={options.disabled}>
                        <span className="p-3"> Anterior&nbsp; </span>
                        <Ripple />
                    </button>
                )
            },
            'NextPageLink': (options) => {
                return (
                    <button type="button" className={options.className} onClick={options.onClick} 
                        disabled={options.disabled}>
                        <span className="p-3"> Siguiente </span>
                        <Ripple />
                    </button>
                )
            },
            'PageLinks': (options) => {
                if ((options.view.startPage === options.page && options.view.startPage !== 0) || 
                        (options.view.endPage === options.page && 
                            options.page + 1 !== options.totalPages)) 
                {
                    const className = classNames(options.className, { 'p-disabled': true });

                    return <span className={className} style={{ userSelect: 'none' }}>...</span>;
                }

                return (
                    <button type="button" className={options.className} onClick={options.onClick}>
                        {options.page + 1}
                        <Ripple />
                    </button>
                )
            },
            'RowsPerPageDropdown': (options) => {
                const dropdownOptions = [
                    { label: 5, value: 5 },
                    { label: 20, value: 20 },
                    { label: 50, value: 50 },
                    { label: 'Todos', value: options.totalRecords }
                ];

                return <Dropdown value={options.value} options={dropdownOptions}
                    onChange={options.onChange} />;
            },
            'CurrentPageReport': (options) => {
                return (
                    <span>
                        <span style={{ color: 'var(--text-color)', 
                            userSelect: 'none', width: '120px', textAlign: 'center' }}>
                            &nbsp; &nbsp;( {options.first} - {options.last} de {options.totalRecords} )
                        </span>
{/*                         <span className="mx-3" style={{ color: 'var(--text-color)', userSelect: 'none' }}>
                            &nbsp; &nbsp; Ir a 
                            <InputText size="2" className="ml-1" value={this.state.currentPage}
                                tooltip={this.state.pageInputTooltip}
                                onKeyDown={(e) => this.onPageInputKeyDown(e, options)} 
                                onChange={this.onPageInputChange}/>
                        </span> */}
                    </span>
                )
            }
        };

        const asociarDetalle = 
            <React.Fragment>
                <div className="p-fluid">
                    <div className="p-field p-grid">
                        <Dropdown optionLabel="descripcion" style={{width: '50vw'}} 
                            options={this.props.listaTipoAsociacion} 
                            onChange={(e) => this.setState({selectedTipoAsociacion: e.value})} 
                            placeholder="Seleccione el tipo de asociación"
                            value={this.state.selectedTipoAsociacion}/>
                    </div>
                </div>
            </React.Fragment>

        const normaAsocTemplate = (option) => {
            return (
                <div>
                    <div>
                        <i className="pi pi-eye" style={{'fontSize': '1.2em'}}></i>
                        &nbsp;<strong>{option.normaAsociadaId.nombre}</strong>
                        &nbsp;({option.tipoAsociacionId.descripcion}) - &nbsp;
                        <small>Expedición: {option.normaAsociadaId.fechaElaboracion}</small>
                    </div>
                </div>
            );
        }

        const asociadasDialog =
            <React.Fragment>
                <ListBox optionLabel="normaAsociadaId.nombre" 
                    value={this.props.listaNormaAsocAju?.normaAsociadaId?.rutaArchivo}
                    options={this.props.listaNormaAsocAju}
                    itemTemplate={normaAsocTemplate}
                    onChange={(e) => this.getArchivosNorma(e.value.normaAsociadaId)} />
                <br></br>
                <small><i className="pi pi-info-circle" style={{'fontSize': '1.5em'}}></i>&nbsp;&nbsp;
                <b>Para visualizar el archivo seleccione la norma de su interés.</b></small>
            </React.Fragment>

        const asociadasDialogFooter = 
            <React.Fragment>
                <Button label="Cerrar" icon="pi pi-times"
                    onClick={() => this.hideDialog('verAsociadaDialog')} 
                    className="p-button-secondary" />
            </React.Fragment>

        const borrarNormaDialog =
            <React.Fragment>
                <InputTextarea rows={4} cols={30} value={this.borrarNormaTexto}
                    onChange={(e) => this.setState({borrarNormaTexto: e.target.value})} />
                <br></br>
                <small><i className="pi pi-info-circle" style={{'fontSize': '1em'}}></i>&nbsp;&nbsp;
                Por favor ingrese el motivo por el que se elimina la norma.</small>
            </React.Fragment>

        const borrarNormaDialogFooter = 
            <React.Fragment>
                <Button label="Cerrar" icon="pi pi-times"
                    onClick={() => this.hideDialog('verBorraNormaDialog')} 
                    className="p-button-secondary" />
                <Button label="Borrar" icon="pi pi-trash"
                    onClick={() => this.borrarNorma()} 
                    className="p-button-danger" />
            </React.Fragment>

        let tablaResultado;
        
        if (this.props.tabla === "ConsultaUsuario") {
            tablaResultado =
                <DataTable value={this.props.listaNormas} lazy
                        className="p-datatable-sm p-datatable-responsive-demo" 
                        paginator resizableColumns columnResizeMode="fit"
                        paginatorTemplate={template1} paginatorPosition="both"
                        first={this.state.first1}
                        rows={this.state.numRegistros} onPage={this.onCustomPage1}
                        loading={this.state.loading}
                        totalRecords={this.state.totalRecords}
                        emptyMessage="No hay resultados" style={{'fontSize': '0.90em'}}
                >
                    <Column field="tipoNorma.descripcion" header="Tipo"
                        body={this.tipoBodyTemplate} className="column-width25" />
                    <Column field="descripcion" header="Descripción/Epígrafe"
                        body={this.descripcionBodyTemplate} />
                    <Column header="Acciones" body={this.actionBodyTemplate} className="column-width17"/>
                </DataTable>        
        } else {
            tablaResultado =
                <DataTable value={this.props.listaNormas} lazy
                        className="p-datatable-sm p-datatable-responsive-demo" 
                        loading={this.state.loading}
                        totalRecords={this.state.totalRecords}
                        emptyMessage="No hay resultados" style={{'fontSize': '0.90em'}}
                >
                    <Column field="tipoNorma.descripcion" header="Tipo"
                        body={this.tipoBodyTemplate} className="column-width25" />
                    <Column field="descripcion" header="Descripción/Epígrafe"
                        body={this.descripcionBodyTemplate} />
                    <Column header="Acciones" body={this.actionBodyTemplate} className="column-width17"/>
                </DataTable>        

        }

        return ( 
            <div className="box p-shadow-7 datatable-responsive-demo" >
                <Toast ref={(el) => this.toast = el} />

                {tablaResultado}
                <Dialog visible={this.state.entityDialog} style={{ width: '750px' }} 
                        header="Detalles del documento" modal className="p-fluid" 
                        onHide={() => this.hideDialog('entityDialog')} >
                    {componenteEditar}
                </Dialog>

                <Dialog visible={this.state.asociarDialog} style={{width: '50vw'}} 
                        header="Asociar Normas"  modal={false} className="p-fluid" 
                        onHide={() => this.hideDialog('asociarDialog')}
                        footer={this.renderFooter('asociarDialog')} >
                    {asociarDetalle}
                </Dialog>

                <Dialog visible={this.state.verAsociadaDialog} style={{width: '50vw'}} 
                        header="Normas Asociadas" modal className="p-fluid" 
                        onHide={() => this.hideDialog('verAsociadaDialog')}
                        footer={asociadasDialogFooter} >
                    {asociadasDialog}
                </Dialog>

                <Dialog visible={this.state.verBorraNormaDialog} style={{width: '50vw'}} 
                        header="Borrar Norma" modal={false} className="p-fluid" 
                        onHide={() => this.hideDialog('verBorraNormaDialog')}
                        resizable={false}
                        baseZIndex={3000}
                        footer={borrarNormaDialogFooter} >
                    {borrarNormaDialog}
                </Dialog>

            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        dataList: state.listaNorma,
        selectedNorma: state.norma.selectedNorma,
        listaTipoAsociacion: state.asociacionNorma.tipoAsociacionNorma,
        normaAsocState: state.norma.normaAsocStatus,
        normaAsocAction: state.norma.normaAsocAction,
        normaAction: state.norma.normaAction,
        parametrosBusqueda: state.listaNorma.parametros,
        listaNormaAsociada: state.norma.listaNormaAsociada,
        listaNormaAsocAju: state.asociacionNorma.listaNormaAsociadaAju
    }
}

const mapDispatchToProps = () => {
    return {
        setSelectedNorma,
        saveNorma,
        saveNormaAsociada,
        getListaNorma,
        getListaNormaConsultada,
        getListaNormaReciente,
        getNormaAsociada,
        getNormaFile,
        deleteNorma
    }
};
 
export default connect( mapStateToProps, mapDispatchToProps() ) (TablaResultados);