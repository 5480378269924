import React, { useState, useRef, useEffect }  from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useFormik } from 'formik';
import * as Yup from 'yup';

import { Button } from 'primereact/button';
import { Calendar } from 'primereact/calendar';
import { Card } from 'primereact/card';
import { Checkbox } from 'primereact/checkbox';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from "primereact/inputtext";
import { Panel } from 'primereact/panel';
import { Ripple } from 'primereact/ripple';
import { Toast } from 'primereact/toast';
import { Toolbar } from 'primereact/toolbar';

import { getEntidadEmisora } from '../../store/actions/entidadEmisoraActions';
import { getTiposNorma } from '../../store/actions/tiposNormaActions';

import { yearRange } from '../../helper/Util';
import { getListaNorma } from '../../store/actions/listaNormaActions';

// FIXME: Revisar validaciones
const entitySchema = Yup.object().shape({
    numeroNorma: Yup.string()
        .min(1, 'Muy corto').max(10, 'Muy largo'),
    fechaElaboracionDesde: Yup.date()
        .max(Yup.ref('fechaElaboracionHasta'), 
            'La fecha de inicio debe ser menor o igual a la de finalización'),
    fechaElaboracionHasta: Yup.date()
        .min(Yup.ref('fechaElaboracionDesde'), 
            'La fecha de finalización debe ser mayor o igual a la de inicio')
});

const SearchComponent = (props) => {
    const toast = useRef(null);
    const dispatch = useDispatch();

    const { tiposNormaList, entidadEmisoraList } = useSelector(
        state => ({
            tiposNormaList: state.tiposNorma,
            entidadEmisoraList: state.entidadEmisora
        })
    )

    const showToast = (severityValue, summaryValue, detailValue) => {   
        toast.current.show({severity: severityValue, summary: summaryValue, detail: detailValue});   
    }
    
    const formik = useFormik({
        initialValues: {
            textoBuscar: '',
            numeroNorma: '',
            tipoNorma: '',
            entidad: '',
            fechaElaboracionDesde: '',
            fechaElaboracionHasta: '',
            buscarContenido: false
        }, 
        onSubmit: (values, actions) => {
            values = {...values,
                activo: props.administrador === true ? 'todos' : 'true',
                page: 0,
                size: 5}
            dispatch(getListaNorma(values)).then ( (resultado) => {
                if (resultado?.totalElements === 0 ) {
                    showToast('warn', 'Atención', 'La busqueda no retorno resultados.');
                } else {
                    let mensaje = "";
                    if ( resultado.totalElements === 1 ) {
                        mensaje = "Se encontró 1 resultado para la búsqueda.";
                    } else {
                        mensaje = 'Se encontraron ' + resultado.totalElements + 
                        ' resultados para la búsqueda.';
                    }
                    showToast('info', 'Información', mensaje);
                }
            });
            actions.setSubmitting(false);
        },
        validationSchema: entitySchema
    });

    const handleKeyPress = (event) => {
        if(event.key === 'Enter'){
            event.preventDefault();   
            formik.handleSubmit();
        }
      }
    
    // eslint-disable-next-line no-unused-vars
    const [panelCollapsed, setPanelState] = useState(true);
    const [gotListasSelect, setListasSelect] = useState(true);

    useEffect( () => {
        if (gotListasSelect) {
            dispatch(getEntidadEmisora());
            dispatch(getTiposNorma());
            setListasSelect(false);
        }
    }, [gotListasSelect, dispatch]);


    const template = (options) => {
        const toggleIcon = options.collapsed ? 'pi pi-chevron-down' : 'pi pi-chevron-up';
        const className = `${options.className} justify-content-start`;
        const titleClassName = `${options.titleClassName} `;

        return (
            <div className={className}>
                <button className={options.togglerClassName} onClick={options.onTogglerClick}>
                    <span className={toggleIcon}></span>
                    <Ripple />
                </button>
                <span className={titleClassName}>
                    Filtros de búsqueda
                </span>
            </div>
        )
    }
    
    const footer = 
        <span>
            <Panel 
                headerTemplate={template} toggleable
                collapsed={panelCollapsed} 
                className="panel-filter" >
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-12 p-md-6">
                        <label htmlFor="tipoNorma">Tipo de Norma</label>
                        <Dropdown id="tipoNorma" value={formik.values.tipoNorma} name="tipoNorma"
                            optionLabel="descripcion" optionGroupLabel="descripcion" optionGroupChildren="items"
                            options={tiposNormaList.tiposNorma} onChange={formik.handleChange} 
                            placeholder="Seleccione" />
                    </div>
                    <div className="p-field p-col-12 p-md-6">
                        <label htmlFor="entidad">Entidad emisora</label>
                        <Dropdown id="entidad" value={formik.values.entidad} name="entidad"
                            options={entidadEmisoraList.entidadEmisora} onChange={formik.handleChange} 
                            optionLabel="descripcion" optionGroupLabel="descripcion" optionGroupChildren="items"
                            placeholder="Seleccione" />
                    </div>
                    <div className="p-field p-col-12 p-md-6">
                        <label htmlFor="fechaElaboracionDesde">Fecha de inicio</label>

                        <Calendar id="fechaElaboracionDesde" value={formik.values.fechaElaboracionDesde} locale="es"
                            monthNavigator yearNavigator yearRange={yearRange}
                            onChange={formik.handleChange}  showIcon />
                        {formik.errors.fechaElaboracionDesde ? (
                            <small id="fechaElaboracionDesde-help" className="p-invalid p-error p-d-block">
                                {formik.errors.fechaElaboracionDesde}</small>
                        ): null}
                    </div>
                    <div className="p-field p-col-12 p-md-6">
                        <label htmlFor="fechaElaboracionHasta">Fecha de finalización</label>

                        <Calendar id="fechaElaboracionHasta" value={formik.values.fechaElaboracionHasta} locale="es"
                            monthNavigator yearNavigator yearRange={yearRange}
                            onChange={formik.handleChange}  showIcon />

                        {formik.errors.fechaElaboracionHasta ? (
                            <small id="fechaElaboracionHasta-help" className="p-invalid p-error p-d-block">
                                {formik.errors.fechaElaboracionHasta}</small>
                        ): null}
                    </div>
                    <div className="p-field p-col-12 p-md-6">
                        <label htmlFor="numeroNorma">Número</label>
                        <InputText id="numeroNorma" type="text" name="numeroNorma" 
                            { ... formik.getFieldProps('numeroNorma')} />
                        {formik.touched.numeroNorma && formik.errors.numeroNorma ? (
                            <small id="numeroNorma-help" className="p-error p-d-block">
                                {formik.errors.numeroNorma}</small>
                        ): null}
                    </div>
                    <div className="p-field p-col-12 p-md-6 p-field-checkbox">
                        <Checkbox id="buscarContenido" 
                            checked={formik.values.buscarContenido}  
                            onChange={formik.handleChange} />
                        <label htmlFor="buscarContenido">Buscar en el contenido del documento</label>
                        {formik.touched.buscarContenido && formik.errors.buscarContenido ? (
                            <small id="buscarContenido-help" className="p-invalid p-error p-d-block">
                                {formik.errors.buscarContenido}</small>
                        ): null}
                    </div>
                </div>
            </Panel>
        </span>;

    const rightContents = (
        <React.Fragment>
            <Button label="Limpiar" type="button" icon="pi pi-refresh" className="p-button-secondary" 
                onClick={formik.handleReset}/>
        </React.Fragment>
    );

    return (
        <div>
            <form className="search-component" onKeyDown={handleKeyPress} >
                <Toast ref={toast} /> 
                <Card footer={footer}>
                    <div className="p-inputgroup buscar">
                        <InputText id="textoBuscar" type="text" 
                            { ... formik.getFieldProps('textoBuscar')} 
                            onKeyPress={handleKeyPress} 
                            placeholder="Buscar por palabras específicas ... Tenga en cuenta la ortografía correcta y el uso de tildes."/>
                        <Button icon="pi pi-search" className="p-button-primary"
                            onClick={() => formik.handleSubmit()} type="button"/>
                            {/* <button type="submit">Buscar</button> */}
                    </div>
                    Ingrese las palabras de interés para la búsqueda. Ej. Acuerdo 035 2005, Resolución 0135 2015
                </Card>
                <Toolbar right={rightContents} />
            </form>
        </div>
    )
}

export default SearchComponent;
