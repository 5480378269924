import React, { useState, useEffect, useRef, } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from "react-router-dom";

import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';
import { Toolbar } from 'primereact/toolbar';

import SearchComponent from '../dashboard/SearchComponent';
import TablaResultados from '../tabla-resultados/TablaResultados';
// import './AsociarNormaDetalle.css';
import { getTipoAsociacionNorma } from '../../store/actions/tipoAsociacionNormaActions';
import NormaCard from '../norma-card/NormaCard';

const AsociarNormaDetalle = (props) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const toast = useRef(null);
    // eslint-disable-next-line no-unused-vars
    const { selectedNorma, listaTipoAsociacion, listaNormas, currentResult } = useSelector(
        state => ({
            selectedNorma: state.norma.selectedNorma,
            currentResult: 1,
            listaNormas: state.listaNorma,
            listaTipoAsociacion: state.asociacionNorma.tipoAsociacionNorma
        })
    )

    // eslint-disable-next-line no-unused-vars
    const showToast = (severityValue, summaryValue, detailValue) => {
        toast.current.show({ severity: severityValue, summary: summaryValue, detail: detailValue });
    }

    // eslint-disable-next-line no-unused-vars
    const [panelCollapsed, setPanelState] = useState(true);
    useEffect( () => {
        dispatch(getTipoAsociacionNorma())
        .then( (res) => { 
            console.log(res)
            /*
            if (normaStatus === 200 || normaStatus === 201) {
                toast.current.show({ severity: 'success', summary: 'Atención', 
                    detail: 'Se guardó la norma correctamente.', life: 3000 });
            } else {
                toast.current.show({ severity: 'error', summary: 'Atención', 
                detail: 'No se pudo guardar la norma.', life: 3000 });
            }
            */
        });
    }, [dispatch, panelCollapsed])

    const rightContents = (
        <React.Fragment>
            <Button label="Volver" icon="pi pi-chevron-left" className="p-button-secondary" 
                onClick={() => { history.goBack(); }} />
        </React.Fragment>
    )
    
    const isResultados = currentResult > 0 ? true : false;
    let resultados;
    if (isResultados) {
        resultados = 
            <TablaResultados numRegistros="5" admin={true} asociar={true}
                paginador={listaNormas.pageOptions}
                tabla="ConsultaUsuario"
                listaNormas={listaNormas.listaNorma}>
            </TablaResultados>
    } else {
        resultados = <span style={{color: "black"}}>Utilice la barra de búsqueda para consultar los 
                    documentos de su interés.</span>
    }

    return (
        <div>
            <Toast ref={toast} />
            <h3 className="p-sm-12 p-col-12" style={{color: "black"}}>Asociar Norma</h3>
            <Toolbar right={rightContents} />
            <div className="p-grid">
                <div className="p-sm-4 p-col-12 p-mt-2" >
                    <NormaCard  norma={selectedNorma} masConsultadas={false}></NormaCard> 
                </div>
                <div className="p-sm-8 p-col-12 p-mt-2" >
                    <SearchComponent administrador={true}/>
                    {resultados}
                </div>
            </div>
        </div>
    );
}

AsociarNormaDetalle.propTypes = {};

AsociarNormaDetalle.defaultProps = {};

export default AsociarNormaDetalle;
