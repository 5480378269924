import { GET_USER_ROLES, USER_ROLES_ERROR } from '../types'
import { USER_LOGIN, USER_LOGIN_ERROR, USER_LOGOUT } from '../types'
import axios from '../../api/axiosAuth';
import qs from 'qs';

export const getToken = (user, pwd) => {
    const LOGIN_URL = '/oauth/token';
    const clientId = process.env.REACT_APP_AUTH_CLIENT_ID;
    const clientSecret = process.env.REACT_APP_AUTH_SECRET;
    const credentials = btoa(clientId + ':' + clientSecret);
    const state = Math.random().toString(36).substring(2, 15) +
        Math.random().toString(36).substring(2, 15) +
        Math.random().toString(36).substring(2, 15);
    const basicAuth = 'Basic ' + credentials;

    return async (dispatch, getState) => {
        let errorMsg = '';
    
        try {
            let data = qs.stringify({
                response_type: 'code',
                grant_type: 'password',
                username: user,
                password: pwd,
                client_id: clientId,
                redirect_uri: process.env.REACT_APP_AUTH_URL + 'login',
                // redirect_uri: window.location.origin,
                scope: 'read',
                state: state 
            });

            const response = await axios.post(LOGIN_URL,
                data,
                {
                    headers: { 
                        'Authorization': basicAuth,
                        'Content-Type': 'application/x-www-form-urlencoded' },
                    data: data
                },
            );

            let authorities = [];
            if (response?.data?.UserAuthentication?.authorities) {
                authorities = response?.data?.UserAuthentication.authorities;

                authorities = authorities.filter(function (el) {
                    return el.authority === 'ROLE_ADMIN_NORMATIVIDAD';
                });

                if (authorities.length > 0) {
                    dispatch( { type: USER_LOGIN, payload: response.data });
                    dispatch( { type: GET_USER_ROLES, payload: response.data });
                } else {
                    errorMsg='Usuario no autorizado.!';
                }
            } else {
                errorMsg='Usuario no autorizado.!';
            }
        } catch (err) {
            if (!err?.response) {
                errorMsg='¡El servidor de autenticación no responde!';
            } else if (err.response?.status === 400) {
                errorMsg='Usuario o password no válidos.';
            } else if (err.response?.status === 401) {
                errorMsg='Usuario no autorizado!';
            } else {
                errorMsg='Login Failed';
            }
            dispatch( { type: USER_LOGIN_ERROR, payload: '' });
            dispatch( { type: USER_ROLES_ERROR, payload: '' });
        }

        return errorMsg;
    }
}

export const logout = () => {
    return async (dispatch, getState) => {
        dispatch( { type: USER_LOGOUT, payload: '' });
    }
}