import { GET_TIPO_ASOCIACION_NORMA, TIPO_ASOCIACION_NORMA_ERRORS } from '../types'
import axios from 'axios'

export const getTipoAsociacionNorma = () => async dispatch => {
    let errorMsg = 'OK'; 
    try{
        const res = await axios.get(
            process.env.REACT_APP_API_URL + 'lista-detalle/dominio?valor=TIPO_ASOCIA')
        dispatch( {
            type: GET_TIPO_ASOCIACION_NORMA,
            payload: res.data
        })
    }
    catch(e){
        if (!e?.response) {
            errorMsg='¡El servidor de autenticación no responde!';
        } else if (e.response?.status === 400) {
            errorMsg='Usuario o password no válidos.';
        } else if (e.response?.status === 401) {
            errorMsg='Usuario no autorizado!';
        } else {
            errorMsg='Login Failed';
        }
        dispatch( {
            type: TIPO_ASOCIACION_NORMA_ERRORS,
            payload: e
        })
    }

    return errorMsg;
}