import React from 'react';
import ReactDOM from 'react-dom';
import store from './store/store';
import { Provider } from 'react-redux';
import { BrowserRouter } from "react-router-dom";

import './index.css';
import './App.css';
import App from './App';
import Header from './components/layout/header/Header';
import Footer from './components/layout/footer/Footer';
import Ad from './components/layout/ad/Ad';
import { AuthProvider } from './context/AuthProvider';

ReactDOM.render(
    <React.StrictMode>
        <Provider store={store}>
            <div className="wrapper">
                <Header appTitle="Buscador Normativo"></Header>
                <div className="content">
                    <BrowserRouter>
                        <AuthProvider>
                            <App />
                        </AuthProvider>
                    </BrowserRouter>
                </div>
                <Ad adMsg="SGSI 2020@"></Ad>
                <Footer footerMsg="Universidad Pedagógica Nacional - Educadora de Educadores"></Footer>
            </div>
        </Provider>
    </React.StrictMode>,
    document.getElementById('root')
);
