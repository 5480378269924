import React, { Component } from 'react'
import { connect } from 'react-redux';

import { TabView, TabPanel } from 'primereact/tabview';

import SearchComponent from './SearchComponent';
import TablaResultados from '../tabla-resultados/TablaResultados';

import { getListaNormaConsultada } from '../../store/actions/listaNormaConsultadaActions';
import { getListaNormaReciente } from '../../store/actions/listaNormaRecienteActions';
import { getListaNorma } from '../../store/actions/listaNormaActions';

import './dashboard.css';
class DashboardContainer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            activeIndex: 0,
            panelCollapsed: true, 
            currentResult: 1
        }
    }

    componentDidMount() {
        this.props.getListaNormaConsultada(0, 5);
        this.props.getListaNormaReciente(0, 10);
    }

    render() {
        const isResultados = this.state.currentResult > 0 ? true : false;
        let resultados;
        if (isResultados) {
            resultados = 
                <TablaResultados numRegistros="5"
                    listaNormas={this.props.normasLista.listaNorma}
                    tabla="ConsultaUsuario"
                    paginador={this.props.normasLista.pageOptions}>
                </TablaResultados>
        } else {
            resultados = <span style={{color: "black"}}>Utilice la barra de búsqueda para consultar los 
                        documentos de su interés.</span>
        }
        return (
            <div className='dashboard'>
                <SearchComponent administrador={false}/>
                
                <TabView activeIndex={this.state.activeIndex} 
                        onTabChange={(e) => this.setState({ activeIndex: e.index })}>
                    <TabPanel header="Resultados">
                        {resultados}
                    </TabPanel>
                    <TabPanel header="Más consultados">
                        <TablaResultados numRegistros="5"
                            listaNormas={this.props.normasMasConsultadas.listaNormaMasConsultada}
                            paginador={this.props.normasMasConsultadas.pageOptions}
                            tabla="MasConsultadas"
                            masConsultadas={true} >
                        </TablaResultados>
                    </TabPanel>
                    <TabPanel header="Recientes">
                        <TablaResultados numRegistros="5"
                            listaNormas={this.props.normasMasRecientes.listaNormaMasReciente}
                            tabla="Recientes"
                            paginador={this.props.normasMasRecientes.pageOptions}>
                        </TablaResultados>
                    </TabPanel>
                </TabView>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        normasMasConsultadas: state.listaNormaMasConsultada,
        normasMasRecientes: state.listaNormaMasReciente,
        normasLista: state.listaNorma
    }
}

const mapDispatchToProps = () => {
    return {
        getListaNormaConsultada, 
        getListaNormaReciente,
        getListaNorma
    }
}
export default connect( mapStateToProps, mapDispatchToProps() )(DashboardContainer);
