import { GET_ENTIDAD_EMISORA } from '../types'

const initialState = {
    loading: true
}

// eslint-disable-next-line import/no-anonymous-default-export
export default function(state = initialState, action) {

    switch(action.type) {
        case GET_ENTIDAD_EMISORA:
        return {
            ...state,
            entidadEmisora: action.payload,
            loading:false
        }
        default: return state
    }
}