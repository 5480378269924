import { GET_USER_ROLES, USER_ROLES_ERROR } from '../types'
import { USER_LOGIN, USER_LOGIN_ERROR, USER_LOGOUT } from '../types'

const initialState = {
    authentication : {
        isAuthenticated: false,
        token: ''
    }
}

const authenticationReducer = (state = initialState, action) => {
    switch(action.type) {
        case USER_LOGIN:
            return {
                ...state,
                isAuthenticated: true,
                token: action.payload.access_token,
                loading:false
            }
        case USER_LOGIN_ERROR:
            return {
                ...state,
                authentication : {
                    isAuthenticated: false,
                    token: ''
                },
                loading:false
            }            
        case USER_ROLES_ERROR:
            return {
                ...state,
                userRoles: [],
                loading:false
            }            
        case GET_USER_ROLES:
            return {
                ...state,
                userRoles: action.payload.UserAuthentication.authorities,
                loading:false
            }            
        case USER_LOGOUT:
            return {
                ...state,
                isAuthenticated: false,
                token: ''
            }            
        default: return state
    }
}

export default authenticationReducer;