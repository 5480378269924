import { GET_TIPOS_NORMA, TIPOS_NORMA_ERRORS } from '../types'
import axios from 'axios'

export const getTiposNorma = () => async dispatch => {
    
    try{
        const res = await axios.get(
            process.env.REACT_APP_API_URL + 'tipo-lista/detalle-agrupado?texto=TIPO_NORMA')
        dispatch( {
            type: GET_TIPOS_NORMA,
            payload: res.data
        })
    }
    catch(e){
        dispatch( {
            type: TIPOS_NORMA_ERRORS,
            payload: console.log(e),
        })
    }

}