import React, { useState, useRef, useEffect }  from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from "react-router-dom";
import { useFormik } from 'formik';
import * as Yup from 'yup';

import { Button } from 'primereact/button';
import { Calendar } from 'primereact/calendar';
import { Card } from 'primereact/card';
import { Dropdown } from 'primereact/dropdown';
import { FileUpload } from 'primereact/fileupload';
import { InputText } from "primereact/inputtext";
import { InputTextarea } from 'primereact/inputtextarea';
import { SelectButton } from 'primereact/selectbutton';
import { Toast } from 'primereact/toast';
import { Toolbar } from 'primereact/toolbar';
import { classNames } from 'primereact/utils';

import { getEntidadEmisora } from '../../store/actions/entidadEmisoraActions';
import { getListaNorma } from '../../store/actions/listaNormaActions';
import { getNormaFile } from '../../store/actions/normaActions';
import { getTiposNorma } from '../../store/actions/tiposNormaActions';
import { saveNorma, saveNormaFile } from '../../store/actions/normaActions';
import { yearRange, toDate, showFileLink } from '../../helper/Util';

import './edit-norma.css'

const entitySchema = Yup.object().shape({
    numero: Yup.string()
        .min(2, 'Muy corto').max(10, 'Muy largo').required('Obligatorio'),
    descripcion: Yup.string()
        .min(4, 'Muy corto').max(1000, 'Muy largo').required('Obligatorio'),
    nombre: Yup.string()
        .min(4, 'Muy corto').max(255, 'Muy largo').required('Obligatorio'),
    tipoNorma: Yup.string().required('Obligatorio'),
    entidadEmisora: Yup.string().required('Obligatorio'),
    fechaElaboracion: Yup.date().required('Obligatorio')
        .nullable().default(undefined),
    fileUpload: Yup.mixed().when('rutaArchivo', {
        is: (rutaArchivo) => rutaArchivo?.length === 0,
        then: Yup.string().required('Obligatorio')}),
    esActivo: Yup.string().required('Obligatorio')
});

const EditNormaComponent = (props) => {
    
    const dispatch = useDispatch();
    const { tiposNormaList, entidadEmisoraList,
            // eslint-disable-next-line no-unused-vars
            fileStatus, fileMessage, normaStatus, normaAction,
            parametrosBusqueda  } = useSelector(
        state => ({
            tiposNormaList: state.tiposNorma,
            entidadEmisoraList: state.entidadEmisora,
            fileStatus: state.norma.fileStatus,
            fileMessage: state.norma.fileMessage,
            normaStatus: state.norma.normaStatus,
            normAction: state.norma.normaAction,
            parametrosBusqueda: state.listaNorma.parametros
        })
    );

    // eslint-disable-next-line no-unused-vars
    const [panelCollapsed, setPanelState] = useState(true);

    const getFile = (normaFile) => {
        dispatch(getNormaFile(normaFile))
        .then( (res) => {
            if ( res.fileMessage !== "") {
                toast.current.show({ severity: 'error', summary: 'Atención', 
                        detail:  "El archivo no está disponible para su revisión en estos momentos." });
            } else {
                showFileLink(res.fileContent);
            }
        })
        .catch( (e) => {
            console.log("*** e*", e)
        });
    }  

    useEffect( () => {
        dispatch(getEntidadEmisora());
        dispatch(getTiposNorma());
    }, [panelCollapsed, dispatch]);
    const toast = useRef(null);

    // eslint-disable-next-line no-unused-vars
    const showToast = (severityValue, summaryValue, detailValue) => {
        toast.current.show({ severity: severityValue, summary: summaryValue, detail: detailValue });
    }

    const formik = useFormik({
        initialValues: {
            numero: props?.selectedRow ? props.selectedRow?.numero: '',
            descripcion: props?.selectedRow ? props.selectedRow.descripcion : '',
            nombre: props?.selectedRow ? props.selectedRow.nombre : '',
            tipoNorma: props?.selectedRow ? props.selectedRow.tipoNorma : '',
            entidadEmisora: props?.selectedRow ? props.selectedRow.entidadEmisora : '',
            fechaElaboracion: props?.selectedRow ? 
                    toDate(props.selectedRow.fechaElaboracion) : "",
            fileUpload: '', // props?.selectedRow ? props.selectedRow.rutaArchivo : '',
            rutaArchivo: props?.selectedRow?.rutaArchivo ? props.selectedRow.rutaArchivo : '',
            esActivo: props?.selectedRow ? props.selectedRow.esActivo : true,
            textoNorma: props?.selectedRow?.textoNorma ? props.selectedRow.textoNorma : '',
            id: props?.selectedRow ? props.selectedRow.id : '',
            fecCreacion: props?.selectedRow?.fecCreacion,
            usuCreacion: props?.selectedRow?.usuCreacion
        },
        onSubmit: (values, actions) => {
            let params = {
               ...parametrosBusqueda,
                size: 5,
                page: 0}

            // if (values?.fileUpload?.name || values?.rutaArchivo?.length !== 0) {
            if (values?.fileUpload?.name) {
                dispatch(saveNormaFile(values))
                .then( (rta) => {
                    if (rta && rta.fileStatus !== 200) {
                        toast.current.show({ severity: 'error', summary: 'Atención', 
                            detail: 'Error creando el archivo de soporte.', life: 3000 });
                        actions.setSubmitting(false);
                    } else {
                        if (rta && rta.fileStatus === 200) {
                            values.rutaArchivo = rta.fileMessage.split(':')[1].trim();
                        }
                        dispatch(saveNorma(values))
                        .then( ( res ) => {
                            if (res === 200 || res === 201) {
                                toast.current.show({ severity: 'success', summary: 'Atención', 
                                    detail: 'Se guardó la norma correctamente.', life: 3000 });
                                actions.resetForm();
                                onClearUpload();
                                actions.setFieldValue("fileUpload", '');
                                dispatch(getListaNorma(params));
                            } else {
                                toast.current.show({ severity: 'error', summary: 'Atención', 
                                    detail: 'No se pudo guardar la norma.', life: 3000 });
                                actions.setSubmitting(false);
                            }
                        });
                    }
                })
            } else  {
                if (values?.rutaArchivo === "" ) {
                    toast.current.show({ severity: 'error', summary: 'Atención', 
                        detail: 'No olvide cargar el archivo.', life: 3000 });
                } else {
                    dispatch(saveNorma(values))
                    .then( ( res ) => {
                        if (res === 200 || res === 201) {
                            toast.current.show({ severity: 'success', summary: 'Atención', 
                                detail: 'Se guardó la norma correctamente.', life: 3000 });
                            actions.resetForm();
                            onClearUpload();
                            actions.setFieldValue("fileUpload", '');
                            dispatch(getListaNorma(params));
                        } else {
                            toast.current.show({ severity: 'error', summary: 'Atención', 
                                detail: 'No se pudo guardar la norma.', life: 3000 });
                            actions.setSubmitting(false);
                        }
                    });
                }
            }
        },
        validationSchema: entitySchema
    });

    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);
    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };

    const onBasicUpload = (event) => {
        formik.setFieldValue("fileUpload", event.files[0]);
        toast.current.show({severity: 'info', summary: 'Success', detail: 'El archivo ha sido leído'});
    }

    const onClearUpload = (event) => {
        formik.setFieldValue("fileUpload", '');
    }

    const activoOptions = [{name: 'Activo', code: true}, {name: 'Inactivo', code: false}]; 

    let verArchivo = '';

    if (props?.selectedRow?.rutaArchivo) {
        verArchivo = 
            <React.Fragment>
                <label htmlFor="viewFile">Ver Archivo</label>
                <Button icon="pi pi-file-pdf" className="p-button p-button-secondary p-mr-2" 
                    onClick={() => getFile(props.selectedRow.rutaArchivo)} id="viewFile"
                    label="Ver Archivo" type="button" />
            </React.Fragment>
    }

    const footer = <span>
        <div className="p-fluid p-formgrid p-grid">
            <div className="p-field p-col-12 p-md-6">
                <label htmlFor="tipoNorma"> Tipo de Norma </label>
                <Dropdown id="tipoNorma" value={formik.values.tipoNorma} name="tipoNorma"
                    options={tiposNormaList.tiposNorma} onChange={formik.handleChange}
                    optionLabel="descripcion" optionGroupLabel="descripcion" 
                    optionGroupChildren="items" placeholder="Seleccione" 
                    className={classNames({ 'p-error': isFormFieldValid('tipoNorma') })} />
                {getFormErrorMessage('tipoNorma')}
            </div>
            <div className="p-field p-col-12 p-md-6">
                <label htmlFor="entidadEmisora"> Entidad emisora</label>
                <Dropdown id="entidadEmisora" value={formik.values.entidadEmisora} name="entidadEmisora"
                    options={entidadEmisoraList.entidadEmisora} onChange={formik.handleChange}
                    optionLabel="descripcion" optionGroupLabel="descripcion" 
                    optionGroupChildren="items" placeholder="Seleccione"
                    className={classNames({ 'p-error': isFormFieldValid('entidadEmisora') })} />
                {getFormErrorMessage('entidadEmisora')}
            </div>
            <div className="p-field p-col-12 p-md-4">
                <label htmlFor="numero">Número</label>
                <InputText id="numero" type="text" name="numero"
                    {...formik.getFieldProps('numero')} 
                    className={classNames({ 'p-error': isFormFieldValid('numero') })} />
                {getFormErrorMessage('numero')}
            </div>
            <div className="p-field p-col-12 p-md-8">
                <label htmlFor="nombre"> Nombre</label>
                <InputText id="nombre" type="text" name="nombre"
                    className={classNames({ 'p-error': isFormFieldValid('nombre') })}
                    {...formik.getFieldProps('nombre')} />
                {getFormErrorMessage('nombre')}
            </div>
            <div className="p-field p-col-12 p-md-4">
                <label htmlFor="fechaElaboracion"> Fecha de elaboración</label>
                <Calendar id="fechaElaboracion" value={formik.values.fechaElaboracion} locale="es"
                    monthNavigator yearNavigator yearRange={yearRange}
                     onChange={formik.handleChange} showIcon
                    className={classNames({ 'p-error': isFormFieldValid('fechaElaboracion') })} />
                {getFormErrorMessage('fechaElaboracion')}
            </div>

            <div className="p-field p-col-12 p-md-8">
                <label htmlFor="fileUpload"> Cargar Archivo</label>
                <FileUpload name="fileUpload" url="./upload" customUpload
                        uploadHandler={onBasicUpload} 
                        onClear={onClearUpload} 
                        chooseLabel="Seleccionar"
                        uploadLabel="Cargar"
                        cancelLabel="Cancelar"
                    className={classNames({ 'p-error': isFormFieldValid('fileUpload') })} >
                    Seleccione y cargue la norma.
                </FileUpload>
                {getFormErrorMessage('fileUpload')}
            </div>

            <div className="p-field p-col-4 p-md-4">
                <label htmlFor="esActivo">Activo</label>
                <SelectButton value={formik.values.esActivo} 
                    optionLabel="name" optionValue="code" 
                    options={activoOptions} onChange={(e) => formik.handleChange} 
                    {...formik.getFieldProps('esActivo')} autoResize 
                    className={classNames({ 'p-error': isFormFieldValid('esActivo') })}/>
                {getFormErrorMessage('esActivo')}
            </div>

            <div className="p-field p-col-4 p-md-4">
                {verArchivo}
            </div>

            <div className="p-field p-col-12 p-md-6">
                <label htmlFor="descripcion"> Descripción</label>
                <InputTextarea rows={5} cols={30}
                    {...formik.getFieldProps('descripcion')} 
                    className={classNames({ 'p-error': isFormFieldValid('descripcion') })} />
                {getFormErrorMessage('descripcion')}
            </div>

            <div className="p-field p-col-12 p-md-6">
                <label htmlFor="textNorma">Texto</label>
                <InputTextarea rows={5} cols={30}
                    {...formik.getFieldProps('textoNorma')} 
                    className={classNames({ 'p-error': isFormFieldValid('textoNorma') })} />
                {getFormErrorMessage('textoNorma')}
            </div>
        </div>
    </span>;

    const history = useHistory();

    const volverNormaOnClick = () => {
        if (props.tablaResultados) {
            props.onHideFunction();
        } else {
            history.push('/admin-norma');
        }
    }

    const rightContents = (
        <React.Fragment>
            <Button label="Volver" icon="pi pi-chevron-left" 
                            className="p-button-secondary p-mr-2" type="button"
                            onClick={() => volverNormaOnClick()} />
            <Button label="Guardar" icon="pi pi-check" type="button" 
                            className="p-button-success"
                            onClick={formik.handleSubmit}
                            disabled={!(formik.dirty && formik.isValid)} />
        </React.Fragment>
    );

    return (
        <React.Fragment>
            <Toast ref={toast} />
            <form className="search-component">
                <Card footer={footer}>
                    <h3>Mantenimiento de Normas</h3>
                </Card>
                <Toolbar right={rightContents} />
            </form>
            {/* {normaAction} */}
        </React.Fragment>
    )
}

export default EditNormaComponent;