import React, { useRef} from 'react';
import { useDispatch } from 'react-redux';

import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';
import { getExcelNormas } from '../../store/actions/normaActions';
import { showExcelLink } from '../../helper/Util';

const DownloadButton = () => {
    const dispatch = useDispatch();
    const toast = useRef(null);

    const getFile = () => {
        dispatch(getExcelNormas())
            .then((res) => {
                if (res.fileMessage !== "") {
                    toast.current.show({
                        severity: 'error', summary: 'Atención',
                        detail: "El archivo no está disponible para su revisión en estos momentos."
                    });
                } else {
                    showExcelLink(res.fileContent);
                }
            })
            .catch((e) => {
                console.log("*** e*", e)
            });
    }


    // const handleDownload = async () => {
    //     try {
    //         const response = await axios.get('http://localhost:8080/api/excel/download', {
    //             responseType: 'blob',
    //         });

    //         // Create a URL for the file
    //         const url = window.URL.createObjectURL(new Blob([response.data]));
    //         const link = document.createElement('a');
    //         link.href = url;
    //         link.setAttribute('download', 'example.xlsx');
    //         document.body.appendChild(link);
    //         link.click();
    //     } catch (error) {
    //         console.error('Error downloading the file', error);
    //     }
    // };

    return (
        <React.Fragment>
            <Toast ref={toast} />
            <Button icon="pi pi-cloud-download" className="p-button-rounded p-button-outlined p-mr-2"
                onClick={() => getFile()} id="viewExcel"
                label="Descargar Normas" type="button" />
        </React.Fragment>
    );
};

export default DownloadButton;
